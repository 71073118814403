import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import { Card } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const RecipeCard = ({ title, uri, image, image2, imageAlt, imageAlt2, excerpt, className, bodyClassName, verticle }) => {

  return (
    <>
      <Link to={uri}  className={className === null ? '' : className}>
        <Card className="m-2 p-1">
            <Card.Body className={bodyClassName === undefined ? "recipe-card-body" : bodyClassName}>
            <Card.Title>
                    {title}
            </Card.Title>
            <div>
              <span>
              {image === undefined || image === null ? <StaticImage src="../images/dinner-plate4.png" alt="Dinner Plate"/> : 
                <GatsbyImage image={image} alt={imageAlt === undefined || imageAlt === null ? title : imageAlt} />}
                </span>
              { verticle !== undefined && verticle && <br/> }
              
              {image2 === undefined || image2 === null ? <></> : 
              <span className="p-2">
                <GatsbyImage image={image2} alt={imageAlt2 === undefined || imageAlt2 === null ? title : imageAlt2} />
              </span>}
              <br/>
              {parse(excerpt)}
            </div>
            </Card.Body>
        </Card>
      </Link>
    </>
  )
}

export default RecipeCard
